<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Collaborative instruments</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Collaborative instruments
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createCollaborativeInstrument()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add collaborative instrument
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">

              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Collaborative instrument title"
                      v-model="search.info"
                      outlined
                      dense
                      v-on:keyup.enter="searchCollaborativeInstrument()"
                      @input="search.info = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                      outlined  v-on:keyup.enter="searchCollaborativeInstrument()"
                      @input="search.category_id = $event !== null ? $event : ''"
                      dense :items="categories" item-text="title" item-value="id" clearable
                      v-model="search.category_id"
                  >
                    <template v-slot:label>
                      Collab category
                    </template>
                  </v-select>

                </v-col>

                <v-col class="d-flex" cols="12" sm="3">
                  <v-select
                      :items="search_statuses"
                      v-model="search.status"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined dense
                      v-on:keyup.enter="searchCollaborativeInstrument()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn
                      @click.prevent="searchCollaborativeInstrument()"
                      class="btn btn-primary w-35 float-right"
                      :loading="loading"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Collab instrument</th>
                  <th class="px-3">Collab category</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <tr v-show="collaborative_instruments.length" v-for="item in collaborative_instruments">
                    <td class="px-3">
                      <a @click="editCollaborativeInstrument(item)"
                         class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ item.title }}
                      </a>
                    </td>
                    <td class="px-3">
                        {{ item.category_name }}
                    </td>
                    <td class="px-3">
                          <span class="badge"
                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                          >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" >
                              <a class="navi-link" @click="editCollaborativeInstrument(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteCollaborativeInstrument(item.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="collaborative_instruments.length == 0">
                    <td  class="px-3 text-center" colspan="10">Items not found.</td>
                  </tr>

                </template>
              </table>
              <b-pagination
                  v-if="collaborative_instruments.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCollaborativeInstruments"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update ref="create-and-update" @refresh="getAllCollaborativeInstruments"></create-and-update>
  </v-app>
</template>
<script>
import CollaborativeInstrumentService from "@/services/product/collaborative-instrument/CollaborativeInstrumentService";
import CollaborativeInstrumentCategoryService from "@/services/product/collaborative-instrument-category/CollaborativeInstrumentCategoryService";
import CreateAndUpdate from "./CreateAndUpdate";

const collaborativeInstrumentCategory=new CollaborativeInstrumentCategoryService();
const collaborativeInstrument=new CollaborativeInstrumentService();
export default {
  components: {
    CreateAndUpdate
  },
  data(){
    return{
      collaborative_instruments:[],
      total: null,
      perPage: null,
      page: null,
      loading:false,
      search: {
        info: '',
        status: '',
      },
      search_statuses: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      categories:[],
    }
  },
  methods: {
    createCollaborativeInstrument(){
      this.$refs['create-and-update'].createCollaborativeInstrument();
    },
    searchCollaborativeInstrument(){
      this.getAllCollaborativeInstruments();
    },

    getAllCollaborativeInstruments() {
      this.loading = true;
      collaborativeInstrument
          .paginate(this.search, this.page)
          .then(response => {
            this.collaborative_instruments = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    editCollaborativeInstrument(item){
      this.$refs['create-and-update'].editCollaborativeInstrument(item);
    },
    deleteCollaborativeInstrument(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            collaborativeInstrument
                .delete(item)
                .then((response) => {
                  this.getAllCollaborativeInstruments();
                  this.$snotify.success("Collaborative instrument deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    getAllActiveCategories(){
      collaborativeInstrumentCategory
          .getActiveData()
          .then(response => {
            this.categories=response.data.categories;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
  },
  mounted() {
    this.getAllCollaborativeInstruments();
    this.getAllActiveCategories();
  }
}
</script>